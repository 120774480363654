import formatDateMixin from './date-formatter-mixin.js'
import { useCartStore } from '../stores/CartStore'
import { useUserStore } from '../stores/UserStore'
import { usePromoCodeStore } from '../stores/PromoCodeStore'
export default {
	data() {
		return {
			overlayIsShowing: false,
			overlayTimeout: null,
			overlayCancelTimeout: null
		}
	},
	mixins: [formatDateMixin],
	methods: {
		showLoadingOverlay() {
			if( this.overlayTimeout ) { clearTimeout(this.overlayTimeout); }
			this.overlayTimeout = setTimeout(() => {
				this.overlayIsShowing = true;
				$('.overlay-loader > .inner').parent().show();
				if( this.overlayCancelTimeout ) { clearTimeout(this.overlayCancelTimeout); }
				// Start another timeout to cancel overlay
				this.overlayCancelTimeout = setTimeout(() => {
					this.hideLoadingOverlay();
				}, 240000);
			}, 100);
		},
		hideLoadingOverlay() {
			if( this.overlayTimeout ) { clearTimeout(this.overlayTimeout); }
			if( this.overlayCancelTimeout ) { clearTimeout(this.overlayCancelTimeout); }
			$('.overlay-loader > .inner').parent().hide();
			this.overlayIsShowing = false;
		},
		showFullHeader() {
			$("body").removeClass("checkoutHeader");
			$(".cart-guide").addClass('cart-active').removeClass('cart-inactive');
			$("#checkout-guide").addClass('cart-inactive');
			$(".cart-ship-arrow").removeClass('cart-active');
			$(".cart-bill-arrow").removeClass('cart-inactive');
			$(".cart-review-arrow").removeClass('cart-inactive');
			$('.cart-ship-arrow .cart-num').html('1');
			$('.cart-bill-arrow .cart-num').html('2');
			if(document.location.href.indexOf("cart")) {
				$(".cart-header").addClass('hide');
			} else {
				$(".cart-header").removeClass('hide');
			}
		},
		showShortHeader() {
			$("body").addClass("checkoutHeader");
			$('.scroll-menu-container').hide();
			if(document.location.href.indexOf("login") == -1) {
				$(".cart-header").removeClass('hide');
			} else {
				$(".cart-header").addClass('hide');
			}
		},
		generateDeliveryMessage(recip, isGiftAnnouncement, isEdelivery) {
			// This is the JS implementation
			// There is also a PHP method for confirmation emails and other non-JS applications
			// PHP method is in \Models\Cart|Recipient
			const weekday = ["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"];
			const monthList = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
			var message = '';

			// WHICH DATE ARE WE GOING TO USE FOR MESSAGING?
			var deliveryDateJSObj = new Date(recip.shipping.delivery_date)

			if(isGiftAnnouncement) {
				deliveryDateJSObj = new Date(recip.announce_it_time)
			}

			var newDate = parseInt(deliveryDateJSObj.getDate());
			var newMinutes = parseInt(deliveryDateJSObj.getMinutes());

			var dow = weekday[deliveryDateJSObj.getDay()];
			var monthName = monthList[deliveryDateJSObj.getMonth()];

			if (isEdelivery) {
				// If time is in past, show 'Send Immediately'
				const deliveryDate = this.$dayjs(recip.shipping.delivery_date)
				if (deliveryDate.isValid() && deliveryDate.isBefore(this.$dayjs())) {
					return 'Send Immediately'
				}

				if (recip.shipping.pickup_time_central?.toLowerCase() === 'send immediately') {
					return recip.shipping.pickup_time_central
				}

				return `${this.$dayjs.utc(recip.shipping.delivery_date).tz('America/Chicago').format('dddd, MMM D')} at ${recip.shipping.pickup_time_central}`

			} else if(['5', '24'].includes(recip.shipping.ship_id) || isGiftAnnouncement) {
				const announceTime = this.$dayjs(recip.announce_it_time)

				return announceTime.format('dddd, MMM D [at] h:mm a [Central]')
			} else {
				// Ship Calendar Grouped Week Messaging takes priority
				if(typeof(recip.ship_calendar_group) != 'undefined' && recip.ship_calendar_group_data.message_shipping_page) {
					message = recip.ship_calendar_group_data.message_shipping_page
				} else if(recip.cart_type == 'P') {
					// Standard Messaging
					if (recip.shipping.ship_select_type === 'day') {
						return this.$dayjs(recip.shipping.delivery_date).format('dddd, MMM D')
					} else if(recip.shipping.ship_select_type == 'week' || recip.shipping.ship_id == '68') {
						Date.prototype.addDays = function(days) {
							var date = new Date(this.valueOf());
							date.setDate(date.getDate() + days);
							return date;
						}
						// Figure out if we are in the delivery week
						function getStartOfWeek(date) {
							// Copy date if provided, or use current date if not
							date = date? new Date(+date) : new Date();
							date.setHours(0,0,0,0);

							// Set date to previous Sunday
							date.setDate(date.getDate() - date.getDay());

							return date;
						}

						var nowSunday = getStartOfWeek()
						var deliveryDate = new Date(recip.shipping.delivery_date).addDays(1)
						var deliverySunday = getStartOfWeek(deliveryDate)
						var rangeStart = null
			
						if(nowSunday.getTime() === deliverySunday.getTime()) {
							// We are in the delivery week, construct the message
							var curDate = new Date()
						
							switch(curDate.getDay()) {
								case 0:
									rangeStart = curDate.addDays(2)
									break
								default:
									rangeStart = curDate.addDays(1)
									break
							}
						} else {
							// Future week range starts on Tuesday
							rangeStart = deliverySunday.addDays(2)
						}

						// Add first day of range
						message += weekday[rangeStart.getDay()] +", " + monthList[rangeStart.getMonth()] + " " + rangeStart.getDate()

						// Now tack on the end of the range
						message += " - " + weekday[deliveryDate.getDay()] +", " + monthList[deliveryDate.getMonth()] + " " + deliveryDate.getDate()
					}
				} else if(recip.cart_type != 'P') {
					message += dow + ", " + monthName + " " + newDate
				}
			}

			return message;
		},
		simpleAddressVaildate(recip, addrType) {
			var requiredFormInputs = ['fname', 'lname']

			if(addrType == 'billing') {
				requiredFormInputs.push('address_1')
				requiredFormInputs.push('city')
				requiredFormInputs.push('state')
				requiredFormInputs.push('postal_code')
				requiredFormInputs.push('email')
				requiredFormInputs.push('phone')
			}

			if (addrType === 'shipping') {
				requiredFormInputs.push('address_1')
				requiredFormInputs.push('city')
				requiredFormInputs.push('state')
				requiredFormInputs.push('postal_code')
			}

			if (addrType === 'ecrt') {
				requiredFormInputs.push('email')
			}

			const inputKeys = {
				fname: {
					label: 'First name',
					rules: [
						{
							test: /^.{0,15}$/,
							message: 'Maximum limit of 15 characters.'
						},
					],
				},
				lname: {
					label: 'Last name', 
					rules: [
						{
							test: /^.{0,15}$/,
							message: 'Maximum limit of 15 characters.'
						}
					],
				},
				company: {
					label: 'Company', 
					rules: [
						{
							test: /^.{0,30}$/,
							message: 'Maximum limit of 30 characters.'
						}
					],
				},
				address_1: {
					label: 'Address',
					rules: [
						{
							test: /^.{0,30}$/,
							message: 'Maximum limit of 30 characters.'
						}
					],
				},
				address_2: {
					label: 'Address 2',
					rules: [
						{
							test: /^.{0,30}$/,
							message: 'Maximum limit of 30 characters.'
						}
					],
				},
				city: {
					label: 'City',
					rules: [
						{
							test: /^.{0,19}$/,
							message: 'Maximum limit of 19 characters.'
						}
					],
				},
				postal_code: {
					label: 'Zip code', 
					rules: [
						{
							test: /^.{0,10}$/,
							message: 'Maximum limit of 10 characters.'
						},
						{
							test: /^(\d{5}|\d{9}|\d{5}-\d{4})$/,
							message: 'Please enter a valid zip code.'
						}
					],
				},
				email: {
					label: 'Email address',
					rules: [
						{
							test: /^.{0,50}$/,
							message: 'Maximum limit of 50 characters.'
						},
						{
							test: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
							message: 'Email address is not valid.',
						}
					],
				},
				phone: {
					label: 'Phone number',
					rules: [
						{
							test: /^.{0,14}$/,
							message: 'Maximum limit of 14 characters.',
						},
						{
							test: /^[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4}$/,
							message: 'Please enter a valid 10-digit phone number.'
						}
					],
				},
				state: {
					label: 'State',
				}
			}

			var validationErrors = {
				fname: {
					error: false,
					message: '',
				},
				lname: {
					error: false,
					message: '',
				},
				company: {
					error: false,
					message: '',
				},
				address_1: {
					error: false,
					message: '',
				},
				address_2: {
					error: false,
					message: '',
				},
				city: {
					error: false,
					message: '',
				},
				state: {
					error: false,
					message: '',
				},
				postal_code: {
					error: false,
					message: '',
				},
				email: {
					error: false,
					message: ''
				},
				phone: {
					error: false,
					message: ''
				},
				ship_date: {
					error: false,
					message: ''
				},
				gift_message: {
					error: false,
					message: ''
				},
				announce_it_message: {
					error: false,
					message: ''
				}
			}

			// Simple check for something in each of the inputs
			let formIsValid = true;

			// Check that all required fields are defined in recipient
			requiredFormInputs.forEach((key) => {
				const definition = inputKeys[key]

				if (!recip[key]) {
					validationErrors[key].error = true
					validationErrors[key].message = `${definition?.label || 'This field'} is required.`
					formIsValid = false
				}
			})

			// Test each key for validation rules
			Object.keys(recip).forEach((key) => {
				const definition = inputKeys[key]

				if (recip[key] && definition?.rules && !validationErrors[key]?.error) {
					definition.rules.forEach(({ test, message }) => {
						if (!test.test(recip[key])) {
							validationErrors[key].error = true
							validationErrors[key].message = message
							formIsValid = false
						}
					})
				}
			})

			if(recip.announce_it_send_flag && !recip.email) {
				validationErrors.email.error = true
				validationErrors.email.message = "A valid email address is required"
				formIsValid = false;
			}

			return { formIsValid: formIsValid, validationErrors: validationErrors }
		},
		validatePassword(password) {
			// Passwords must contain:
			// - at least 1 lowercase character
			// - at least 1 uppercase character
			// - at least 1 number
			// - at least 8 characters total
			const pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;

			// Check if password matches the pattern
			return pattern.test(password);
		},
		emailValidate(emailAddress) {
			let emailValidationPattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

			return emailValidationPattern.test(emailAddress)
		},
		htmlEntities(str) {
			if (str) {
				return $('<div />').html(str).text();
			} else {
				return '';
			}
		},
		fireRemoveFromCartEvent(line) {
			///  Google Tag Manager
			var data_layer_object = {
				'event': 'removeFromCart',
				'ecommerce': {
					'remove': {                                 // 'remove' actionFieldObject measures.
						'products': [{                          //  removing a product to a shopping cart.
							'name': stripEntities(line.sku_name), // stripEntities defined in global.js strips html special character encoded entities (&amp; &nbsp;)
							'id': line.item_id,
							'price': line.price,
							'quantity': line.qty
						}]
					}
				}
			};
			
			window.dataLayer.push(data_layer_object);	
		},
		fireAddCartSpecialEvent(line) {
			///  Google Tag Manager
			var data_layer_object = {
				  'event': 'updateCart',
				'ecommerce': {
					'add': {
						'products': [{
							'name': stripEntities(line.sku_name),
							'id': line.item_id,
							'price': line.price,
							'quantity': 1
						}]
					}
				}
			};
		
			window.dataLayer.push(data_layer_object);	
		},
		fireChangeLineSKUEvent(line, sku) {
			///  Google Tag Manager
			var data_layer_object = {
				  'event': 'updateCart',
				'ecommerce': {
					'remove': {
						'products': [{
							'name': stripEntities(line.sku_name),
							'id': line.item_id,
							'price': line.price,
							'quantity': line.qty
						}]
					},
					'add':{
						'products': [{
							'name':stripEntities(sku.sku_name),
							'id': sku.item_id,
							'price': sku.price,
							'quantity': line.qty
						}]
					}
				}
			}

			window.dataLayer.push(data_layer_object);
		},
		fireUpdateListrakCartEvent(cartData){
			if(cartData.length > 0){
				for(var i = 0; i <cartData.length; i++){
					_ltk.SCA.AddItemWithLinks(cartData[i].item_id, cartData[i].qty, cartData[i].price, stripEntities(cartData[i].sku_name));
				}
				if(window.dataLayer.length && window.dataLayer[0].ecommerce && window.dataLayer[0].ecommerce.cartGUID){
					_ltk.SCA.CartLink = window.dataLayer[0].ecommerce.cartGUID;
				}
				_ltk.SCA.Submit();
			}else{
				_ltk.SCA.ClearCart();
			}
		},
		fireIncreaseLineQtyEvent(line, diff) {
			// console.log("increase");
			// console.log(diff);
			///  Google Tag Manager
			var data_layer_object = {
				'event': 'updateCart',
				'ecommerce': {
					'add': {                                    // 'remove' actionFieldObject measures.
						'products': [{                          //  removing a product to a shopping cart.
							'name': stripEntities(line.sku_name),
							'id': line.item_id,
							'price': line.price,
							'quantity': diff
						}]
					}
				}
			}

			window.dataLayer.push(data_layer_object);		
		},
		fireDecreaseLineQtyEvent(line, diff) {
			///  Google Tag Manager
			var data_layer_object = {
				'event': 'updateCart',
				'ecommerce': {
					'remove': {                                 // 'remove' actionFieldObject measures.
						'products': [{                          //  removing a product to a shopping cart.
							'name': stripEntities(line.sku_name),
							'id': line.item_id,
							'price': line.price,
							'quantity': diff
						}]
					}
				}
			}

			window.dataLayer.push(data_layer_object);		
		},
		fireRecordCheckoutDecisionEvent(step, decisionValue) {
			///  Google Tag Manager
			window.dataLayer.push({
				'event': 'checkoutDecision',
				'ecommerce': {
					'checkout_option': {
						'actionField': {'step': step, 'option': decisionValue}
					}
				}
			})
		},
		clearStoresAndBailOut() {
			// Flush everything and bounce to home page

			// CART
			let cartStore = useCartStore()
			cartStore.completeCart()

			// USER
			let userStore = useUserStore()
			userStore.clearCustomer()

			// PROMO CODE
			let promoCodeStore = usePromoCodeStore()
			promoCodeStore.clearCode()

			window.location.href = "/"
		},
		getPage() {
			let pathname = window.location.pathname
			pathname = pathname.replace(/\/$/, "")
			return pathname.split("/").pop()
		}
	}
}

