<template>
	<div>
		<div :id="'message-container-'+crecId" class="shipping-not-complete">{{ shippingFormMessage }}</div>
		<form class="sg-form cart-form" name="shippingAddressForm" novalidate @submit.prevent="onSubmit">
			<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 open-recip-ship-date-anchor">
				<div class="col-md-6 col-md-6 col-lg-offset-3 col-md-offset-3 col-sm-12 col-xs-12 p-lr-5 sm-p-lr-0">
					<span>Fields marked with <span class="required-asterisk color-red-orange">*</span> are required.</span>
				</div>
			</div>
			<div id="top-of-form" class="col-xs-12" style="padding: 0">
				<!--////  Address Form  ///// -->

				<div class="flexbox">
					<div class="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12" :class="{'has-error': validationErrors.fname.error}">
						<div class="col-lg-3 col-md-3 col-sm-12 col-xs-12 p-lr-5 sm-p-lr-0 text-right text-left-sm">
							<label class="align-self-c" :for="'fname-'+recipient.crec_id">
								<span class="required-asterisk color-red-orange">*</span>
								FIRST NAME
							</label>
						</div>
						<div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 p-lr-5 sm-p-lr-0">
							<input :id="'fname-'+recipient.crec_id" v-model="editableRecipient.fname" v-maxlength="15" name="fname" type="text" class="form-control" :autocomplete="isMyself ? 'shipping given-name' : 'off'" :disabled="inputDisabled" required>
							<span v-if="validationErrors.fname.error" class="color-primary inline-block ng-binding">{{ validationErrors.fname.message }}</span>
						</div>
					</div>
				</div>

				<div class="flexbox">
					<div class="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12" :class="{'has-error': validationErrors.lname.error}">
						<div class="col-lg-3 col-md-3 col-sm-12 col-xs-12 p-lr-5 sm-p-lr-0 text-right text-left-sm">
							<label class="align-self-c" :for="'lname-'+recipient.crec_id">
								<span class="required-asterisk color-red-orange">*</span>
								LAST NAME
							</label>
						</div>
						<div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 p-lr-5 sm-p-lr-0">
							<input :id="'lname-'+recipient.crec_id" v-model="editableRecipient.lname" v-maxlength="15" required name="lname" type="text" class="form-control" :autocomplete="isMyself ? 'shipping family-name' : 'off'" :disabled="inputDisabled">
							<span v-if="validationErrors.lname.error" class="color-primary inline-block ng-binding">{{ validationErrors.lname.message }}</span>
						</div>
					</div>
				</div>

				<div class="flexbox">
					<div class="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12" :class="{'has-error': validationErrors.company.error}">
						<div class="col-lg-3 col-md-3 col-sm-12 col-xs-12 p-lr-5 sm-p-lr-0 text-right text-left-sm">
							<label class="align-self-c" :for="'company-'+recipient.crec_id">
								COMPANY NAME
							</label>
						</div>
						<div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 p-lr-5 sm-p-lr-0">
							<input :id="'company-'+recipient.crec_id" v-model="editableRecipient.company" v-maxlength="30" name="company" type="text" class="form-control" autocomplete="off" :disabled="inputDisabled">
							<span v-if="validationErrors.company.error" class="color-primary inline-block ng-binding">{{ validationErrors.company.message }}</span>
						</div>
					</div>
				</div>

				<div class="flexbox">
					<div class="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12" :class="{'has-error': validationErrors.address_1.error}">
						<div class="col-lg-3 col-md-3 col-sm-12 col-xs-12 p-lr-5 sm-p-lr-0 text-right text-left-sm">
							<label class="align-self-c" :for="'address1-'+recipient.crec_id">
								<span class="required-asterisk color-red-orange">*</span>
								ADDRESS 1
							</label>
						</div>
						<div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 p-lr-5 sm-p-lr-0">
							<input :id="'address1-'+recipient.crec_id" v-model="editableRecipient.address_1" v-maxlength="30" required name="address1" type="text" class="form-control" autocomplete="shipping address-line1" :disabled="inputDisabled">
							<span v-if="validationErrors.address_1.error" class="color-primary inline-block ng-binding">{{ validationErrors.address_1.message }}</span>
						</div>
					</div>
				</div>

				<div class="flexbox">
					<div class="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12" :class="{'has-error': validationErrors.address_2.error}">
						<div class="col-lg-3 col-md-3 col-sm-12 col-xs-12 p-lr-5 sm-p-lr-0 text-right text-left-sm">
							<label class="align-self-c" :for="'address2-'+recipient.crec_id">
								ADDRESS 2
							</label>
						</div>
						<div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 p-lr-5 sm-p-lr-0">
							<input :id="'address2-'+recipient.crec_id" v-model="editableRecipient.address_2" v-maxlength="30" name="address2" type="text" class="form-control" autocomplete="shipping address-line2" :disabled="inputDisabled">
							<span v-if="validationErrors.address_2.error" class="color-primary inline-block ng-binding">{{ validationErrors.address_2.message }}</span>
						</div>
					</div>
				</div>

				<div class="flexbox">
					<div class="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12" :class="{'has-error': validationErrors.city.error }">
						<div class="col-lg-3 col-md-3 col-sm-12 col-xs-12 p-lr-5 sm-p-lr-0 text-right text-left-sm">
							<label class="align-self-c" :for="'city-'+recipient.crec_id">
								<span class="required-asterisk color-red-orange">*</span>
								CITY
							</label>
						</div>
						<div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 p-lr-5 sm-p-lr-0">
							<input :id="'city-'+recipient.crec_id" v-model="editableRecipient.city" v-maxlength="19" required name="city" type="text" class="form-control" autocomplete="shipping address-level2" :disabled="inputDisabled">
							<span v-if="validationErrors.city.error" class="color-primary inline-block ng-binding">{{ validationErrors.city.message }}</span>
						</div>
					</div>
				</div>

				<div class="flexbox">
					<div class="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12" :class="{ 'has-error': validationErrors.state.error }">
						<div class="col-lg-3 col-md-3 col-sm-12 col-xs-12 p-lr-5 sm-p-lr-0 text-right text-left-sm">
							<label class="align-self-c" :for="'state-'+recipient.crec_id"><span class="required-asterisk color-red-orange">*</span> State</label>
						</div>
						<div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 p-lr-5 sm-p-lr-0">
							<div class="arrow bg-color-form-well" :class="{ 'has-error': validationErrors.state.error }">
								<select :id="'state-'+recipient.crec_id" v-model="editableRecipient.state" required name="state" class="form-control" autocomplete="shipping address-level1">
									<option value="">-- SELECT --</option>
									<option v-for="state in states" :key="state.label" :value="state.id">{{ state.label }}</option>
								</select>
							</div>
							<span v-if="validationErrors.state.error" class="color-primary inline-block ng-binding">{{ validationErrors.state.message }}</span>
						</div>
					</div>
				</div>

				<div class="flexbox">
					<div class="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12" :class="{'has-error': validationErrors.postal_code.error }">
						<div class="col-lg-3 col-md-3 col-sm-12 col-xs-12 p-lr-5 sm-p-lr-0 text-right text-left-sm">
							<label class="align-self-c" :for="'zip-'+recipient.crec_id">
								<span class="required-asterisk color-red-orange">*</span>
								ZIP CODE
							</label>
						</div>
						<div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 p-lr-5 sm-p-lr-0">
							<input :id="'zip-'+recipient.crec_id" v-model="editableRecipient.postal_code" v-mask="'#####-####'" v-maxlength="10" required name="postal_code" type="text" class="form-control" autocomplete="shipping postal-code" :disabled="inputDisabled">
							<span v-if="validationErrors.postal_code.error" class="color-primary inline-block ng-binding">{{ validationErrors.postal_code.message }}</span>
						</div>
					</div>
				</div>

				<div class="flexbox">
					<div class="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12" :class="{'has-error': validationErrors.email.error }">
						<div class="col-lg-3 col-md-3 col-sm-12 col-xs-12 p-lr-5 sm-p-lr-0 text-right text-left-sm">
							<label class="align-self-c" :for="'email-'+recipient.crec_id">
								<span v-if="isEmailRequired" class="required-asterisk color-red-orange">*</span>
								EMAIL ADDRESS
							</label>
						</div>
						<div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 p-lr-5 sm-p-lr-0">
							<input :id="'email-'+recipient.crec_id" v-model="editableRecipient.email" v-maxlength="50" :required="isEmailRequired" name="email" type="text" class="form-control" autocomplete="shipping email" :disabled="inputDisabled">
							<div v-if="validationErrors.email.error" class="color-primary">{{ validationErrors.email.message }}</div>
						</div>
					</div>
				</div>

				<div class="flexbox">
					<div class="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12" :class="{'has-error': validationErrors.phone.error }">
						<div class="col-lg-3 col-md-3 col-sm-12 col-xs-12 p-lr-5 sm-p-lr-0 text-right text-left-sm">
							<label class="align-self-c" :for="'phone-'+recipient.crec_id">
								PHONE
							</label>
						</div>
						<div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 p-lr-5 sm-p-lr-0">
							<input :id="'phone-'+recipient.crec_id" v-model="editableRecipient.phone" v-mask="'(###) ###-####'" v-maxlength="14" name="phone" type="text" class="form-control" autocomplete="shipping tel-national" :disabled="inputDisabled">
							<div v-if="validationErrors.phone.error" class="color-primary">
								{{ validationErrors.phone.message }}
							</div>
						</div>
					</div>
				</div>

				<div class="">
					<div class="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
						<div class="flexbox">
							<div class="col-lg-3 col-md-3 col-sm-12 col-xs-12 p-lr-5 sm-p-lr-0 text-right text-left-sm" :class="{'has-error': validationErrors.ship_date.error }">
								<label class=""><span class="required-asterisk color-red-orange">*</span>Delivery</label>
							</div>

							<div v-if="recipient.shippingMode == 'perishable' && recipient.cart_type == 'P'" class="col-lg-7 col-md-7 col-sm-12 col-xs-12 p-lr-5 sm-p-lr-0 p-t-10">
								<span v-if="recipient.shipping.ship_id != '5' && recipient.shipping.ship_id != '24'">
									Arrives <span class="bold">{{ generateDeliveryMessage( editableRecipient ) }}</span>
								</span>
								<slot name="shipping-calendar" v-bind="{ editableRecipient }" />
								<div class="color-primary">{{ validationErrors.ship_date.message }}</div>
							</div>

							<div v-if="recipient.shippingMode == 'nonPerishable' || recipient.cart_type != 'P' && recipient.items" class="col-lg-6 col-md-6 col-sm-12 col-xs-12 p-lr-5 sm-p-lr-0">
								<div class="arrow bg-color-form-well">
									<select v-model="editableRecipient.shipping.ship_id" class="form-control" @change="nonPerishableShippingChange()">
										<option v-for="method in recipient.items[0].nonperishable_ship_methods" :key="method.label" :value="method.ship_id">{{ method.label }}</option>
									</select>
									<div class="color-primary">{{ validationErrors.ship_date.message }}</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
					<div class="col-lg-3 col-md-3 col-md-12 col-sm-12 col-xs-12 p-lr-5 sm-p-lr-0 text-right text-left-sm">
						<label for="">
							<span class="h-uppercase">Is this a gift?</span>
						</label>
					</div>
					<div class="col-lg-6 col-md-6 col-md-12 col-sm-12 col-xs-12 p-lr-5 sm-p-lr-0 input_checkbox_group">
						<div class="input_wrapper m-t-10 m-l-0">
							<input :id="'is-a-gift-'+recipient.crec_id" v-model="editableRecipient.is_a_gift" true-value="1" false-value="0" type="checkbox" :checked="editableRecipient.is_a_gift === '1'">
							<label class="input_checkbox m-r-5" :for="'gift-message-'+recipient.crec_id" style="margin-top: 0 !important; flex-shrink: 0;" @click="editableRecipient.is_a_gift = editableRecipient.is_a_gift === '1' ? '0' : '1'" />
							<p class="h-mixed-case">Customize it with a Gift Message and more.</p>
						</div>
						<div v-if="editableRecipient.is_a_gift === '1'" class="col-lg-12 col-md-12 col-md-12 col-sm-12 col-xs-12 p-lr-0 md-p-r-0">
							<div :class="{'has-error': validationErrors.gift_message.error }">
								<label class="m-l-0" :for="'gift-message-'+recipient.crec_id">Gift Message</label>
								<textarea :id="'gift-message-'+recipient.crec_id" v-model="editableRecipient.gift_message" v-maxlength="240" rows="6" cols="40" class="m-l-0 gift-message-textarea" @input="formatMessage('gift_message')" />
								<span v-if="validationErrors.gift_message.error" class="color-primary">{{ validationErrors.gift_message.message }}</span>
							</div>
							<div class="input_wrapper m-t-10 m-l-0">
								<input :id="'announce-it-checkbox-'+recipient.crec_id" v-model="editableRecipient.announce_it_send_flag" type="checkbox" :checked="editableRecipient.announce_it_send_flag">
								<label class="input_checkbox m-r-5" :for="'announce-it-checkbox-'+recipient.crec_id" style="margin-top: 0 !important;" />
								<p class="h-mixed-case">ANNOUNCE IT via eMail <i id="announce-it-baloon-{{ crecId }}" v-tooltip:bottom="'Enhance your gift with a personalized email that you can schedule or send instantly to your recipient. They&quot;ll appreciate your thoughtfulness!'" class="fa fa-question-circle gc-info" style="color: #295280" @keydown="showAnnounceItBaloon()" /></p>
							</div>
							<div v-if="editableRecipient.announce_it_send_flag">
								<div :class="{'has-error': validationErrors.email.error }">
									<label class="m-l-0">
										<span v-if="isEmailRequired" class="required-asterisk color-red-orange">* </span>
										EMAIL ADDRESS
									</label>
									<input v-model="editableRecipient.email" v-maxlength="50" :required="isEmailRequired" type="text" class="form-control" autocomplete="shipping announce-it-email" @change="syncEmail('announce_it')">
									<div v-if="validationErrors.email.error" class="p-lr-0">
										<span class="color-primary inline-block">{{ validationErrors.email.message }}</span>
									</div>
								</div>
								<div :class="{'has-error': validationErrors.announce_it_message.error }">
									<label class="m-l-0">EMAIL ANNOUNCEMENT MESSAGE</label>
									<div>(Optional... we'll let them know it's from you!)</div>
									<a style="text-decoration: underline;" @click="copyInBoxGiftMessage()">Copy in-box gift message</a>
									<textarea v-model="editableRecipient.announce_it_message" v-maxlength="240" rows="6" cols="40" class="m-l-0 gift-message-textarea" @input="formatMessage('announce_it_message')" />
									<span v-if="validationErrors.announce_it_message.error" class="color-primary">{{ validationErrors.announce_it_message.message }}</span>
								</div>
								<div :class="{'has-error': validationErrors.announce_it_message.error }" class="alternate-delivery-method" style="white-space: nowrap;">
									<label class="align-self-c">Delivery</label>
									<div v-if="!editableRecipient.announce_it_time || editableRecipient.announce_it_time == 'send-immediately'" class="elected-delivery-date">
										<span class="email icon" aria-hidden="true" />
										<span class="email-delivery-label"><strong>Send Immediately</strong></span>
									</div>
									<div v-else class="elected-delivery-date">
										<span class="email icon" aria-hidden="true" />
										<span class="email-delivery-label">Send {{ generateDeliveryMessage(editableRecipient, true) }}</span>
									</div>
									<div style="padding-bottom: 10px; padding-left: 10px">
										<ShippingCalendar
											:recipient="editableRecipient"
											:mode="'announce-it'"
										/>
									</div>
								</div>
							</div>
							<div v-if="recipCount > 4" class="input_checkbox_group">
								<div class="input_wrapper m-t-10 m-l-0">
									<input :id="'apply-to-all-shipments-'+recipient.crec_id" v-model="applyGiftMessageToAll" type="checkbox">
									<label class="input_checkbox m-r-5" :for="'apply-to-all-shipments-'+recipient.crec_id" style="margin-top: 0 !important;" />
									<p class="h-mixed-case">Apply to all shipments?</p>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
					<div class="flexbox">
						<div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 col-lg-offset-3 col-md-offset-3">
							<button type="submit" class="button full pull-right" @click="onDone">
								Done
							</button>
						</div>
					</div>
				</div>
			</div>
			<div class="clearfix" />
		</form>
	</div>
</template>

<script>
import { mapState } from 'pinia'
import formatDateMixin from '../../../mixins/date-formatter-mixin.js'
import checkoutHelpers from '../../../mixins/checkout-global-functions.js'
import { emitter } from '../../../main.js'
const axios = require('axios')
const md5 = require('md5')
import ShippingCalendar from '../../global/global-shipping-calendar.vue'
import { useCartStore } from '../../../stores/CartStore'
import { useUserStore } from '../../../stores/UserStore'
import { watch } from 'vue'

export default {
	components: {ShippingCalendar},
	mixins: [formatDateMixin, checkoutHelpers],
	props: {
		crecId: {
			type: String,
			required: true,
		},
		recipient: {
			type: Object,
			required: true,
		},
		isEditing: {
			type: Boolean,
			default: false,
		},
		validationErrors: {
			type: Object,
			default: () => ({})
		}
	},

	emits: ['done'],

	setup(props) {
		const editableRecipient = props.recipient

		return {
			editableRecipient
		}
	},

	data() {
		return {
			addresses: [],
			states: [],
			inputDisabled: false,
			applyGiftMessageToAll: false,
			applyShippingToAll: false,
			showQASModal: false,
			qasData: null,
			shippingFormMessage: '',
			processing: false,
			messageRules: {
				maxLines: 6,
				maxLineLength: 40
			}
		};
	},

	computed: {
		...mapState(useCartStore, ['specificDayUpgrade', 'groupOvernightUpgradeCost', 'holidayExpeditedShippingMessage', 'recipCount']),
		...mapState(useCartStore, {
			cart: 'fullCart',
		}),

		isMyself() {
			return parseInt(this.recipient.is_myself) === 1
		},

		isEmailRequired() {
			return parseInt(this.editableRecipient.is_a_gift) === 1 && this.editableRecipient.announce_it_send_flag
		}
	},

	created(){
		// Fetch the states list
		axios.get('/api/geo-states')
			.then(response => {
				// handle success
				// process the states data from the server
				Object.entries(response.data.state_values).forEach(entry => {
					this.states.push({id: entry[1].id, label: entry[1].label});
				});
			})
			.catch(error => {
				// handle error
				console.log(error);
			})
			.finally(() => {
				// always executed
			});
	},
	mounted() {
		// User Stuff
		this.userStore = useUserStore();
		this.addresses = this.userStore.addresses;

		// Cart Stuff
		this.cartStore = useCartStore();

		// Listen for recipient addr_id change coming from ShipToSelector
		emitter.on('ship-to-addr-id-changed', (evt) => {
			// Tell the cartStore to change the shipment address
			this.cartStore.recipientChangeSavedAddress(evt.crec_id, evt.uuid, evt.is_new)
		})
		
		watch(
			() => [this.editableRecipient.gift_message, this.editableRecipient.announce_it_message],
			([giftMessage, announceItMessage]) => {
				if (this.validateMessage(giftMessage)) {
					this.validationErrors.gift_message.error = false
					this.validationErrors.gift_message.message = ''
				} else {
					this.formatMessage('gift_message')
				}

				if (this.validateMessage(announceItMessage)) {
					this.validationErrors.announce_it_message.error = false
					this.validationErrors.announce_it_message.message = ''
				} else {
					this.formatMessage('announce_it_message')
				}
			}
		)
	},

	methods: {
		validateMessage(input = '') {
			const lines = input.split('\n')

			if (lines > this.messageRules.maxLines) return false

			for (let i = 0; i < lines.length; i++) {
				if (lines[i].length > this.messageRules.maxLineLength) return false
			}

			return true
		},

		formatMessage(which) {
			// This function enforces the KCS system rules for gift message formatting
			// Max 6 lines of 40 characters each
			// The which variable allows us to apply the same formatting rules to either
			// - the standard in-box gift message
			// - or the announce-it email gift message
			let maxLineLen = this.messageRules.maxLineLength;
			let maxLines = this.messageRules.maxLines;

			let messageLines = this.editableRecipient[which].split("\n");
			for (var i = 0; i < messageLines.length; i++) {
				if (messageLines[i].length <= maxLineLen){
					continue;
				}

				let j = 0;
				let space = maxLineLen;
				while (j++ <= maxLineLen) {
					if (messageLines[i].charAt(j) === " ") space = j;
				}

				if(i < (maxLines - 1)) {
					// if there are more messageLines available
					messageLines[i + 1] = messageLines[i].substring(space + 1) + (messageLines[i + 1] ? " " + messageLines[i + 1] : "");
					messageLines[i] = messageLines[i].substring(0, space);
				} else {
					// If we are on the last line - just remove the characters over the max line length (maxLineLen)
					messageLines[i] = messageLines[i].slice(0, maxLineLen - messageLines[i].length);
				}
			}

			this.editableRecipient[which] = messageLines.slice(0, maxLines).join("\n");
		},
		copyInBoxGiftMessage() {
			this.editableRecipient.announce_it_message = this.editableRecipient.gift_message;
		},
		toggleGiftMessageForAll() {
			if(this.applyGiftMessageToAll != '') {
				this.applyGiftMessageToAll = '';
			} else {
				this.applyGiftMessageToAll = this.crecId;
			}
		},
		showAnnounceItBaloon(event) {
			if (event.which == 32) {
				event.preventDefault();
				if (this.recipient.announceItStuffIsOpen == false) {
					$("#announce-it-baloon"+this.recipient.crec_id).trigger("mouseover");
					this.editableRecipient.announceItStuffIsOpen = true;
				} else {
					$("#announce-it-baloon"+this.recipient.crec_id).trigger("mouseout");
					this.editableRecipient.announceItStuffIsOpen = false;
				}
			}
		},
		syncEmail(winner) {
			if(winner == 'address') {
				this.editableRecipient.announce_it_email = this.recipient.address.email;
			}

			if(winner == 'announce_it') {
				this.editableRecipient.address.email = this.recipient.announce_it_email;
			}
		},

		nonPerishableShippingChange() {
			switch(this.recipient.shipping.ship_id) {
			case "21":
				this.editableRecipient.shipping.ship_select_type = 'week'
				break
			case "30":
			case "33":
				this.editableRecipient.shipping.ship_select_type = 'day'
				break
			}

			for(var i = 0; i < this.recipient.items[0].nonperishable_ship_methods.length; i++) {
				if(this.recipient.shipping.ship_id == this.recipient.items[0].nonperishable_ship_methods[i].ship_id) {
					this.editableRecipient.shipping.ship_date = this.recipient.items[0].nonperishable_ship_methods[i].ship_date
					this.editableRecipient.shipping.delivery_date = this.recipient.items[0].nonperishable_ship_methods[i].ship_date
				}
			}
		},
		
		validationChoiceUseSuggested() {
			this.editableRecipient.address_1	= this.qasData.suggested_address.address_1
			this.editableRecipient.address_2	= this.qasData.suggested_address.address_2
			this.editableRecipient.city			= this.qasData.suggested_address.city
			this.editableRecipient.postal_code	= this.qasData.suggested_address.postal_code
			this.editableRecipient.state		= this.qasData.suggested_address.state
			// this.editableRecipient.res_com		= this.qasData.addr_type

			if(this.qasData.suggested_address.company != 'undefined') {
				this.editableRecipient.company = this.qasData.suggested_address.company;
			}

			this.saveValidationChoice('suggested')
		},
		validationChoiceUsePicklistSelection(picklistKey) {
			let selectedAddress = this.qasData.picklist[picklistKey].address_parts

			this.editableRecipient.address_1	= selectedAddress.address_1;
			this.editableRecipient.address_2	= selectedAddress.address_2;
			this.editableRecipient.city			= selectedAddress.city;
			this.editableRecipient.postal_code	= selectedAddress.postal_code;
			this.editableRecipient.state		= selectedAddress.state;
			// this.editableRecipient.res_com		= this.qasData.addr_type

			if(selectedAddress.company != 'undefined') {
				this.editableRecipient.company = selectedAddress.company;
			}
			this.saveValidationChoice('selected')
		},
		validationChoiceUseMyAddress() {
			this.editableRecipient.res_com = 'U'
			this.saveValidationChoice('mine')
		},
		saveValidationChoice(userChoiceType) {
			// Notify the server of Customer's validation choice

			axios.post('/api/checkout/record-qas-validation-selection', {recip: this.editableRecipient, user_choice_type: userChoiceType})
				.then(async (response) => {
					await this.cartStore.updateRecipient(this.recipient.crec_id, this.editableRecipient)

					setTimeout(() => this.$emit('done', this), 500)
					// emitter.emit('close-recipient-edit-form', {crec_id: this.crecId})
				})
				.catch((error) => {
					this.$sentry.captureException(error)
				})
				.finally(() => {
					this.processing = false;
				});
		},
		generateRecipientFingerprint(recipient) {
			// only if we have data
			let fingerprintString = '';
			let fingerprint = '';

			if(recipient && recipient.fname && recipient.fname.length) {
				fingerprintString = recipient.fname.toLowerCase()
				fingerprintString += ( recipient.lname && recipient.lname.length ) ? recipient.lname.toLowerCase().replace(' ', '') : ''
				fingerprintString += ( recipient.address_1 && recipient.address_1.length ) ? recipient.address_1.toLowerCase().replace(' ', '') : ''
				fingerprintString += ( recipient.address_2 && recipient.address_2.length ) ? recipient.address_2.toLowerCase().replace(' ', '') : ''
				fingerprintString += ( recipient.company && recipient.company.length ) ? recipient.company.toLowerCase().replace(' ', '') : ''
				fingerprintString += ( recipient.city && recipient.city.length ) ? recipient.city.toLowerCase().replace(' ', '') : ''
				fingerprintString += ( recipient.state && recipient.state.length ) ? recipient.state.toLowerCase().replace(' ', '') : ''
				fingerprintString += ( recipient.postal_code && recipient.postal_code.length ) ? recipient.postal_code.toLowerCase().replace(' ', '') : ''
				fingerprintString += ( recipient.email && recipient.email.length ) ? recipient.email.toLowerCase().replace(' ', '') : ''
				fingerprintString += ( recipient.phone && recipient.phone.length ) ? recipient.phone.toLowerCase().replace(' ', '') : ''

				if(fingerprintString.length) {
					fingerprint = md5(fingerprintString)
				}
			}

			return fingerprint
		},

		handleQasComplete(event) {
			if(event.crecId == this.recipient.crec_id && !this.processing) {
				this.processing = true

				switch(event.action) {
				case 'edit':
					this.processing = false
					break
				case 'save-selected':
					this.validationChoiceUsePicklistSelection(event.picklistKey)
					break
				case 'save-suggested':
					this.validationChoiceUseSuggested()
					break
				case 'use-my-address':
					this.validationChoiceUseMyAddress()
					break
				default:
					this.processing = false
				}
			}

			this.showQASModal = false
		},

		async onDone() {
			if (this.applyGiftMessageToAll) {
				await useCartStore().applyGiftMessageToAll(this.editableRecipient)
				this.applyGiftMessageToAll = false
			}
			
			this.$emit('done')
		}
	}
}
</script>

<style>
	.tooltip .tooltip-inner{
		max-width: 600px;
	}
</style>
