<template>
	<div>
		<CheckoutSteps page="shipping" :needs-additional-shipments-step="clubItemRecipients.length > 0" @navigate="onCheckoutStepNavigate" />
		<div class="master-shipping-panel">
			<div class="row">
				<div class="col-md-12">
					<h3 class="m-t-25 m-b-25 text-center-mobile shipping-heading">
						Shipping Details
					</h3>
				</div>
			</div>

			<div class="col-xs-12 col-sm-12 col-md-8 mc-shipping-panel">
				<ShippingRecipient
					v-for="(recipient, index) in recipients"
					ref="shippingRecipient"
					:key="index"
					:recipient="recipient"
					:class="{
						[`line-${index}`]: true,
						'm-t-20': index > 0,
					}"
					:is-editing="editingRecipientId === recipient.crec_id"
					@edit="editingRecipientId = recipient.crec_id"
					@done="onDoneEditingRecipient"
				/>
			</div>

			<div id="order-sum-outer" class="col-xs-12 col-md-4 hidden-xs hidden-sm">
				<div class="order-sum-contain">
					<CartSummary page="shipping" />
				</div>
			</div>
			<br class="clear">
			<CheckoutNavigation page="shipping" @continue="onContinue" />
		</div>
	</div>
</template>

<script>
import { mapState } from 'pinia'
import checkoutHelpers from '../../../mixins/checkout-global-functions.js'
import { emitter } from '../../../main.js'
import { useCartStore } from '../../../stores/CartStore'
import { usePromoCodeStore } from '../../../stores/PromoCodeStore'
import { useUserStore } from '../../../stores/UserStore'
import CartSummary from '../cart-summary.vue'
import CheckoutNavigation from '../checkout-navigation.vue'
import CheckoutSteps from '../checkout-steps.vue'
import ShippingRecipient from '~/components/checkout/shipping/shipping-recipient.vue'

const axios = require('axios')

export default {
	name: 'ShippingPage',
	components: {
		ShippingRecipient,
		CartSummary,
		CheckoutNavigation,
		CheckoutSteps,
	},

	mixins: [checkoutHelpers],

	data() {
		return {
			showShippingNotComplete: false,
			holidayExpeditedShippingMessage: '',
			continueButtonClicked: false,
			fieldErrors: {},
			processing: false,
			editingRecipientId: null,
		}
	},

	computed: {
		...mapState(useCartStore, ['clubItemRecipients']),
		...mapState(useCartStore, {
			cart: 'fullCart',
			recipients: 'getRecipients',
			recipCount: 'recipCount'
		})
	},
	
	async mounted() {
		this.cartStore = useCartStore()
		this.userStore = useUserStore()

		// Login check
		if(this.cartStore.isAutoDelivery && !this.userStore.getIsLoggedIn) {
			window.location.href = "/checkout/login"
		}
		
		//////////////////////////
		// SET UP EVENT HANDLING
		//////////////////////////

		emitter.on('cart-updated', evt => {
			// this.cart = storeToRefs(this.cartStore.cart);
			this.cart = this.cartStore.fullCart
		})

		emitter.on('cart-recipient-deleted', (evt) => {
			this.cart = this.cartStore.fullCart
		})

		emitter.on('close-recipient-edit-form', (evt) => {
			emitter.emit('done-editing-recip')
		})

		emitter.on('cart-updated-from-server', evt => {
			this.hideLoadingOverlay()
		})

		emitter.on('cart-not-found', (evt) => {
			this.clearStoresAndBailOut()
		})
		emitter.on('item-out-of-stock', (evt) => {
			window.location.href = "/checkout/cart"
		})
		emitter.on('cart-recipient-address-updated', (evt) => {
			this.cart = this.cartStore.fullCart
		})
		
		this.showLoadingOverlay()

		try {
			await useCartStore().updateCartDataFromServer()
		} catch (error) {
			console.error(error)
		} finally {
			// if cart is empty, redirect to cart
			if(this.cartStore.getRecipients.length < 1) {
				window.location.href = "/checkout/cart"
			}

			if(!this.cartStore.cartIsValid()) {
				this.clearStoresAndBailOut()
			}
		}

		// Show reduced header while in checkout
		this.showShortHeader()

		this.promoCodeStore = usePromoCodeStore()
		if(this.cart.discount_code) {
			this.promoCodeStore.validateExistingPromoCode(this.cart.discount_code)
		} else {
			this.promoCodeStore.clearCode()
		}

		await this.$nextTick()

		await this.validateAllRecipients({
			showErrors: false,
		})

		this.hideLoadingOverlay()
	},

	methods: {
		async validateAllRecipients(options = {}) {
			if( this.processing ) { return }
			this.showLoadingOverlay()
			this.processing = true

			const invalidRecipients = await Promise.all(this.$refs.shippingRecipient.map(async (ref) => {
				if (!ref.validateRecipient(options)) {
					return ref.recipient
				}

				if (!await ref.saveLineInformation()) {
					return ref.recipient
				}
			})).then((results) => results.filter(Boolean))

			if (invalidRecipients.length) {
				this.editingRecipientId = invalidRecipients[0].crec_id

				this.$nextTick(() => {
					this.scrollToEditRecipient()
				})

				this.processing = false
				this.hideLoadingOverlay()
			} else {
				this.editingRecipientId = null
				return this.doFinalValidation()
			}

			this.hideLoadingOverlay()
			this.processing = false
		},

		async doFinalValidation() {
			this.processing = true

			return this.cartStore.shippingPageFinalValidation()
				.then((response) => {
					if(!response.data.field_errors.recipient && !response.data.field_errors.recipient?.recipient_warnings) {

						// Loads customer data to fetch addresses and name
						if (this.userStore.autoDeliveryRegistration) {
							this.userStore.loadCustomer()
							this.userStore.autoDeliveryRegistration = false
						}

						return true
					} else {
						if (response.data.field_errors.recipient) {
							const recipientIds = Object.keys(response.data.field_errors.recipient)

							if (recipientIds) {
								recipientIds.forEach((crecId) => {
									const ref = this.$refs.shippingRecipient.find((ref) => ref.recipient.crec_id === crecId)
	
									if (ref) {
										ref.handleValidationErrors(response.data.field_errors.recipient[crecId])
									} else {
										this.$sentry.captureException(new Error('Attempt to refrence nonexistant Vue $ref'), (scope) => {
											scope.setContext('recipient', {
												id: crecId
											})

											return scope
										})
									}
								})
	
								this.editingRecipientId = recipientIds[0]
							}
						}

						this.$nextTick(() => { 
							this.scrollToEditRecipient()
						})

						return false
					}
				})
				.finally(() => {
					this.processing = false
					this.hideLoadingOverlay()
				})
		},

		async onContinue() {
			const check = await this.cartStore.cartPageFinalValidation()
			if (check.status !== 'valid') {
				if (['item-out-of-stock', 'insufficient-stock', 'add-on-qualify'].includes(check.reason)) {
					window.location.href = '/checkout/cart'
				}
			}

			if (await this.validateAllRecipients({ showValidationErrors: true })) {
				this.navigateToNext()
			}
		},

		async onDoneEditingRecipient() {
			if (await this.validateAllRecipients({ showErrors: false })) {
				if (this.recipCount === 1) {
					this.navigateToNext()
				} else {
					this.scrollToContinue()
				}
			}
		},

		scrollToEditRecipient() {
			this.$nextTick(() => { 
				if($('.recipient-open-for-edit:visible:first').length && $('.recipient-open-for-edit:visible:first').offset()) {
					$('html, body').animate({
						scrollTop: $('.recipient-open-for-edit:visible:first').offset().top - 75
					}, 500)
				}
			})
		},

		scrollToContinue() {
			this.$nextTick(() => {
				$('html, body').animate({
					scrollTop: $('.master-shipping-panel').prop("scrollHeight") - 600
				}, 500)
			})
		},

		navigateToNext() {
			if(useCartStore().clubItemRecipients.length) {
				window.location.href = "/checkout/additional-shipments"
			} else {
				window.location.href = "/checkout/billing"
			}
		},

		async onCheckoutStepNavigate(event) {
			event.preventDefault()

			const { currentTarget } = event

			if (['/checkout/additional-shipments', '/checkout/billing'].some((path) => currentTarget.href.includes(path))) {
				if (await this.validateAllRecipients()) {
					window.location = currentTarget.href
				}
			} else {
				window.location = event.currentTarget.href
			}
		}
	}
};
</script>
