<template>
	<div class="promo-bars">
		<AdUniversalPromoBar v-if="auto_delivery === 'B'" />
	</div>
</template>

<script>
import AdUniversalPromoBar from './ad-universal-promo-bar.vue'

export default {
	components: {
		AdUniversalPromoBar
	},
    
	props: {
		// eslint-disable-next-line vue/prop-name-casing
		auto_delivery: {
			type: String,
			default: 'A'
		}
	},
}
</script>

<style scoped>
.promo-bars {
    margin-top: -10px;
    margin-bottom: 25px;

    @media (min-width: 768px) {
        margin-top: -25px;
    }
}
</style>