<template>
	<a v-if="mode != 'additional-shipments'" class="anchor-tertiary p-l-10">
		<span v-if="mode == 'e-delivery'">
			<br>
			<span class="color-tertiary" style="cursor: pointer; font-size: 12px" @click="openModal('day')" >
				<i class="fa fa-calendar" style="font-size: 18px" aria-hidden="true" />
				<span style="margin-left:10px">Change Delivery Date</span>
			</span>
		</span>
		<span v-else>
			<span v-if="typeof(recipient) != 'undefined' && recipient.crec_id != '' && !recipient.shipping.ship_id">
				<div class="delivery-date-fake-input">Choose A Delivery Date<i class="fa fa-calendar input-calendar-icon" aria-hidden="true" /></div>
			</span>

			<span :id="'calendar-opener-'+recipient.crec_id" class="color-tertiary" style="cursor: pointer; font-size: 12px" @click="openModal('chooser')">
				<span style="font-size: 12px" class="fa fa-edit" />Change
			</span>
		</span>
	</a>
	<a v-if="mode == 'additional-shipments'" class="">
		<span class="" style="cursor: pointer;" @click="openModal('week')">
			<i class="fa fa-calendar" aria-hidden="true" /> Change date
		</span>
	</a>

	<div v-if="shouldShowOvernightUpgrade" class="input_checkbox_group gift-checkbox" style="display: block; margin-bottom: 0 !important">
		<label for="kcs-overnight-upgrade" class="css-label m-t-0">
			<div class="input_wrapper m-l-0">
				<input :id="'overnight-upgrade-'+recipient.crec_id" v-model="recipient.shipping.overnight_upgrade" type="checkbox" :checked="recipient.shipping.overnight_upgrade">
				<label class="input_checkbox m-r-5" :for="'gift-message-'+recipient.crec_id" style="margin-top: 0 !important;" @click="recipient.shipping.overnight_upgrade = !recipient.shipping.overnight_upgrade" />
				<p class="" style="margin: 0; text-transform: none">UPGRADE to Overnight</p>
			</div>
		</label>
		<div style="color: #98958e; font-size: 12px; padding-left: 32px">(Only ${{ recipient.shipping.overnight_upgrade_cost }} more)</div>
	</div>

	<div v-if="typeof(recipient) != 'undefined' && typeof(recipient.ship_calendar_group_data) != 'undefined' && recipient.ship_calendar_group_data.message_shipping_page && specificDayUpgrade > 0">
		Need it to arrive on a specific date?
		<span
			class="color-tertiary change-specific-day open-calendar"
			@click="openDayModeCalendar"
		>Choose it</span> for as little as ${{ specificDayUpgrade }} more.
		<div>
			<span class="color-tertiary change-specific-day open-calendar">View delivery options</span>.
		</div>
	</div>
	<div v-if="holidayExpeditedShippingMessage">
		<div v-if="(typeof(recipient) != 'undefined' && typeof(recipient.ship_calendar_group_data) == 'undefined' || !recipient.ship_calendar_group_data.message_shipping_page) && groupOvernightUpgradeCost > 0 && recipient.shipping.ship_select_type == 'week'" class="group-overnight-upgrade">
			Need it sooner?
			<span class="color-tertiary change-specific-day open-calendar anchor-tertiary">Choose Overnight Delivery</span> for ${{ groupOvernightUpgradeCost }} more.
			<span class="color-tertiary change-specific-day open-calendar anchor-tertiary">View delivery options</span>.
		</div>
	</div>

	<div v-if="showCalendarChooserModal" class="ship-cal-modal" style="padding: 3px">
		<div class="ship-cal-modal-content shipping-calendar-chooser-modal">
			<button type="button" class="ship-cal-close" @click="closeModal('chooser')" />
			<div class="kcs-shipcal-titlebox m-b-30">
				<div class="kcs-shipcal-title p-b-10">
					SELECT A DELIVERY OPTION
				</div>
			</div>

			<div class="kcs-shipcal">
				<div class="sku_container_radio">
					<div class="sku_container_inner_radio" type="week">
						<div class="sg-form">
							<div class="radio" tabindex="1" @click="openModal('week')" >
								<input id="radio0" type="radio">
								<label for="radio0" class="css-label font-TabletGothic font-md" style="padding: 0; position: absolute;">
									<span style="margin-top: 0px" />
								</label>
								<div style="padding-left: 40px">
									<strong>DIFFERENT DELIVERY WEEK</strong><br>
									<span v-html="chooserWeekTitleHtml" />
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="sku_container_radio">
					<div class="sku_container_inner_radio" type="day">
						<div class="sg-form">
							<div class="radio" tabindex="1" @click="openModal('day')">
								<input id="radio1" type="radio">
								<label for="radio1" class="css-label font-TabletGothic font-md" style="padding: 0; position: absolute;">
									<span style="margin-top: 0px" />
								</label>
								<div style="padding-left: 40px">
									<strong>SPECIFIC DELIVERY DATE</strong><br><span>Premium delivery charges apply</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div v-if="showCalendarWeekMode" class="ship-cal-modal" style="padding: 3px">
		<div class="ship-cal-modal-content week-mode-calendar-modal">
			<LoadingOverlay :visible="loading" />

			<button type="button" class="ship-cal-close" @click="closeModal('week')" />

			<div class="kcs-shipcal-titlebox">
				<div class="kcs-shipcal-title">
					{{ modalScope.calendar_title }}
				</div>
				<div v-if="modalScope.calendar_subtitle" class="kcs-shipcal-subtitle">
					<span v-html="modalScope.calendar_subtitle" />
				</div>
				<div v-if="modalScope.show_apply_all_perishable" class="input_checkbox_group">
					<div class="input_wrapper m-l-0" style="max-width: 100%">
						<input v-model="applyAllSelected" type="checkbox" :checked="applyAllSelected">
						<label class="input_checkbox m-r-5" style="margin-top: 0 !important;" @click="applyAllSelected = !applyAllSelected" />
						<p class="h-mixed-case">Apply the week I select to all food shipments in this order</p>
					</div>
				</div>
			</div>
		
			<div class="kcs-shipcal" :class="modalScope.calendarClass">
				<div class="kcs-shipcal-navbox">
					<a class="go-prev-month color-greyblue" tabindex="1" @click="goPrevMonth()">
						<i class="fa fa-chevron-left" aria-hidden="true" />
					</a>
					<span class="calendar-label">{{ modalScope.month_label }}</span>
					<a class="go-next-month color-greyblue" tabindex="1" @click="goNextMonth()">
						<i class="fa fa-chevron-right" aria-hidden="true" />
					</a>
				</div>
		
				<table class="calendar">
					<thead>
						<th /><th>S</th><th>M</th><th>T</th><th>W</th><th>T</th><th>F</th><th>S</th>
					</thead>
					<tbody>
						<tr
							v-for="(week, index) in modalScope.weeks"
							:id="'week-'+index"
							:class="week.weekClass"
							tabindex="1"
							:ship_calendar_group_id="week.ship_calendar_group_id"
							@click="shippableWeekClick(index)"
							@mouseover="weekMouseOver(index)"
							@mouseleave="weekMouseLeave(index)"
						>
							<td style="font-size: 34px; background-color: #FFF;">
								<i :class="week.checkboxClass" />
							</td>
							<td
								v-for="day in week.days"
								:class="day.dayClass"
								:data-day="day"
							>
								<span class="cal-date">{{ day.date }}</span>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>

	<div v-if="showCalendarDayMode" class="ship-cal-modal" style="padding: 3px">
		<div class="ship-cal-modal-content week-mode-calendar-modal">
			<LoadingOverlay :visible="loading" />

			<button type="button" class="ship-cal-close" @click="closeModal('day')" />
			<div class="kcs-shipcal-titlebox">
				<div class="kcs-shipcal-title">{{ modalScope.calendar_title }}</div>
				<div v-if="modalScope.calendar_subtitle" class="kcs-shipcal-subtitle">
					<span v-html="modalScope.calendar_subtitle" />
				</div>
				<div v-if="modalScope.show_apply_all_perishable" class="input_checkbox_group">
					<div class="input_wrapper m-l-0" style="max-width: 100%">
						<input v-model="applyAllSelected" type="checkbox" :checked="applyAllSelected">
						<label class="input_checkbox m-r-5" style="margin-top: 0 !important;" @click="applyAllSelected = !applyAllSelected" />
						<p class="h-mixed-case">Apply the date I select to all food shipments in this order</p>
					</div>
				</div>
			</div>
		
			<div class="kcs-shipcal day-select-calendar" :class="modalScope.calendarClass">
				<div class="kcs-shipcal-navbox">
					<a class="go-prev-month color-greyblue" tabindex="1" @click="goPrevMonth()">
						<i class="fa fa-chevron-left" aria-hidden="true" />
					</a>
					<span class="calendar-label">{{ modalScope.month_label }}</span>
					<a class="go-next-month color-greyblue" tabindex="1" @click="goNextMonth()">
						<i class="fa fa-chevron-right" aria-hidden="true" />
					</a>
				</div>
			
				<table class="calendar">
					<thead>
						<th v-if="modalScope.isECert">Sun</th>
						<th v-if="modalScope.isECert">Mon</th>
						<th v-if="!modalScope.isECert" class="non-shippable">S</th>
						<th v-if="!modalScope.isECert" class="non-shippable">M</th>
						<th>T</th>
						<th>W</th>
						<th>T</th>
						<th>F</th>
						<th>S</th>
					</thead>
					<tbody>
						<tr v-for="(week, weekIndex) in modalScope.weeks" :key="weekIndex">
							<td v-for="(day, dayIndex) in week.days" :key="dayIndex" :class="day.dayClass" tabindex="1">
								<a style="color: inherit; text-decoration: inherit" @click="shippableDayClick(weekIndex, dayIndex, day, $event)">
									<span class="cal-date">
										{{ day.date }}
										<span v-if="isEdeliveryMode" />
										<span v-if="day.ship_cost && !isEdeliveryMode && !isAnnounceItMode"><br>&nbsp;${{ day.ship_cost }}&nbsp;</span>
										<span v-if="day.freeShip"><br>Free</span>
									</span>
								</a>
							</td>
						</tr>
					</tbody>
				</table>
		
				<div v-if="isEdeliveryMode || isAnnounceItMode" style="text-align:center;margin:20px">
					<button class="button send-immediately" @click="processTimeSelect('send-immediately')">
						SEND IMMEDIATELY
					</button>
				</div>
			</div>
		</div>
	</div>
	
	<div v-if="showTimeSelectModal" class="ship-cal-modal">
		<div class="ship-cal-modal-content shipping-calendar-chooser-modal">
			<p v-if="isAnnounceItMode" class="text-center ecrt-time-chooser-title">
				Please choose the time you would like<br>your announcement to be sent
			</p>
			<p v-if="isEdeliveryMode" class="text-center ecrt-time-chooser-title">
				Please choose the time you would like<br>your E-Certificate to be sent
			</p>

			<p class="text-center">
				<select v-model="deliveryTimeSelected" class="kcs-calendar-pickup-time">
					<option value=""> -- Choose a Time -- </option>
					<option v-for="time in computedTimeOptions" :key="time.label" :value="time.value">{{ time.label }}</option>
				</select>
			</p>

			<div v-if="modalScope.show_apply_all_ecrt" class="input_checkbox_group">
				<div class="input_wrapper m-l-0">
					<input id="apply-to-all-ecrt" v-model="applyAllSelected" type="checkbox">
					<label class="input_checkbox m-r-5" for="apply-to-all-ecrt" style="margin-top: 0 !important; flex-shrink: 0;" />
					<p class="h-mixed-case">Apply this date and time to all eCertificates in my order</p>
				</div>
			</div>

			<p class="text-center m-t-20">
				<button type="submit" class="btn action-btn block pickup-time-save" @click="processTimeSelect('use-selected-time')">
					Save Send Time
				</button>
			</p>
		</div>
	</div>
</template>

<script>
import { emitter } from '../../main'
import formatDateMixin from '../../mixins/date-formatter-mixin.js'
import { useCartStore } from '../../stores/CartStore'
import { mapState } from 'pinia'
import LoadingOverlay from './loading-overlay.vue'
const axios = require('axios')

export default {
	components: {
		LoadingOverlay,
	},

	mixins: [formatDateMixin],

	props: {
		recipient: {
			type: Object,
			required: true,
		},

		isRecipEditForm: {
			type: Boolean,
			default: false,
		},

		mode: {
			type: String,
			default: '',
			validator: (value) => ['', 'additional-shipments', 'e-delivery', 'announce-it'].includes(value)
		},

		recurringOrder: {
			type: Object,
			default: () => ({}),
		},

		clinId: {
			type: [Number, String],
			default: undefined,
		}
	},

	data() {
		return {
			selectedDeliveryDate: null,
			clickedDayData: null,
			clickedDay: null,
			minDeliveryDate: null,
			maxDeliveryDate: null,
			showCalendarChooserModal: false,
			calendarType: '',
			showCalendarWeekMode: false,
			showCalendarDayMode: false,
			showTimeSelectModal: false,
			currentMonth: null,
			__monthCache: [],
			limitShipCode: false,
			isModal: true,
			modalScope: {},
			isPickupMode: false,
			isEdeliveryMode: false,
			isAnnounceItMode: false,
			deliveryTimeSelected: "",
			AK_HI_shipping_cost: '$40',
			applyAllSelected: false,
			loading: false,
		}
	},

	computed: {
		...mapState(useCartStore, ['fullCart', 'specificDayUpgrade', 'groupOvernightUpgradeCost', 'holidayExpeditedShippingMessage']),

		shippingDiscount() {
			if (this.fullCart.recalc_detail?.discount_detail[this.recipient.recipient_guid]?.shipping_discount) {
				return parseFloat(this.fullCart.recalc_detail.discount_detail[this.recipient.recipient_guid].shipping_discount)

			} else {
				return 0
			}
		},

		freeShipFlag() {
			if (this.shippingDiscount >= this.$constants.BASE_SHIPPING_COST) {
				return true
			}

			if (this.recipient.items.some((item) => item.is_perpetual_subscription)) {
				return true
			}

			return this.recipient.items.some((item) => item.ships_free)
		},

		chooserWeekTitleHtml() {
			const shippingDiscountText = this.freeShipFlag ? 'FREE' : `$${(this.$constants.BASE_SHIPPING_COST - parseFloat(this.shippingDiscount)).toFixed(2)}`
			
			const pricePart = this.freeShipFlag || this.shippingDiscount ? `<strike>$${this.$constants.BASE_SHIPPING_COST}</strike> ${shippingDiscountText}` : `$${this.$constants.BASE_SHIPPING_COST}`
			
			const nonContiguousPart = ['AK', 'HI'].includes(this.recipient.state) ? `plus ${this.AK_HI_shipping_cost} for Alaska and Hawaii` : ''

			return `<span>Same price of only ${pricePart} ${nonContiguousPart}</span>`
		},

		shouldShowOvernightUpgrade() {
			if (!this.isRecipEditForm) return false
			if (this.holidayExpeditedShippingMessage) return false
			if (this.recipient.overnight_upgrade_cost <= 0) return false
			if (this.recipient.shipping.ship_select_type === 'week') return false

			return this.recipient.shipping.show_overnight_upgrade
		},

		computedTimeOptions() {
			// Filters out times in the past
			return this.modalScope.timeOptions.filter(({ value }) => {
				return this.$dayjs
					.tz(`${this.clickedDay} ${value}`, 'YYYY-MM-DD HH:mm:ss', 'America/Chicago')
					.isAfter(this.$dayjs.tz(this.$dayjs(), 'America/Chicago'))
			})
		}
	},

	created(){

	},
	mounted() {
		this.cartStore = useCartStore()

		if(this.mode != 'additional-shipments') {
			this.selectedDeliveryDate = this.recipient.shipping.delivery_date ?? new Date().toJSON();
			this.minDeliveryDate = this.recipient.club_item_min_date
			this.maxDeliveryDate = this.mode != 'announce-it' ? null : new Date(this.recipient.shipping.delivery_date)
		} else {
			this.selectedDeliveryDate = this.recurringOrder.delivery_date
			this.minDeliveryDate = new Date(this.recurringOrder.min_delivery_date)
			this.maxDeliveryDate = new Date(this.recurringOrder.max_delivery_date)
		}

		if(this.mode == 'announce-it') {
			this.isAnnounceItMode = true

			if (this.recipient.announce_it_time) {
				this.deliveryTimeSelected = this.formatDate(this.recipient.announce_it_time, "HH:mm:00")
			}
		} else if(this.mode == 'e-delivery') {
			this.isEdeliveryMode = true
		}
	},
	methods: {
		recipLoaded() {
			let loaded = false
			if(this.recipient.crec_id != '') {
				loaded = true
			}

			return loaded
		},
		openModal(which) {
			if(this.isEdeliveryMode || this.isAnnounceItMode) {
				this.showCalendarChooserModal = false
				this.calendarType = 'day'
				this.loadMonth(null)
			} else {
				switch (which) {
				case 'chooser':
					this.showCalendarChooserModal = true
					break
				case 'week':
					this.showCalendarChooserModal = false
					this.calendarType = 'week'
					this.loadMonth(null)
					break
				case 'day':
					this.showCalendarChooserModal = false
					this.calendarType = 'day'
					this.loadMonth(null)
					break
				}
			}
		},
		closeModal(which) {
			// Close by the X button - do nothing
			switch (which) {
			case 'chooser':
				this.showCalendarChooserModal = false
				break
			case 'week':
				this.showCalendarWeekMode = false
				break
			case 'day':
				this.showCalendarDayMode = false
				break
			}
		},
		prepareCalendarModalScope(calendarData, showSelectedDate, weekGroups) {
			var modalScope = { calendarData : calendarData }
			var sku_cart_type = typeof calendarData.sku_cart_type != "undefined" ? calendarData.sku_cart_type : "-"

			modalScope.month_label = calendarData.start_month_label
			modalScope.selectedDeliveryDateString = (this.selectedDeliveryDate ? this.formatDate(this.selectedDeliveryDate, "MMMM D, YYYY") : '')
			modalScope.showIfPickupMode   =   (this.isPickupMode || this.isEdeliveryMode) ? '' : 'display: none'
			modalScope.showIfDeliveryMode = ! (this.isPickupMode || this.isEdeliveryMode) && sku_cart_type != 'S' ? true : false;
			modalScope.showIlfSeasoningMode = ! (this.isPickupMode || this.isEdeliveryMode) && sku_cart_type == 'S' ? '' : 'display: none'
			modalScope.shipState = this.recipient.state
			modalScope.selectedShipCalendarGroupsId = ''
			modalScope.show_apply_all_perishable = this.recipient.show_apply_all_perishable && !this.isEdeliveryMode
			modalScope.show_apply_all_ecrt = this.recipient.show_apply_all_ecrt && this.isEdeliveryMode
			modalScope.timeOptions = calendarData.pickup_times_all_day

			// Set the calendar title
			modalScope.isECert = false;

			if (this.isPickupMode) {
				modalScope.calendar_title = 'Choose A<br />Pick Up Date'
			} else if (this.isEdeliveryMode) {
				modalScope.isECert = true;
				modalScope.calendar_title = 'Choose A Delivery Date'
			} else if (this.calendarType == 'day') {
				modalScope.calendar_title = 'Select a Specific Delivery Date';
				modalScope.calendarClass = 'day-select-calendar';
			} else {
				modalScope.calendar_title = 'Select a Delivery Week';
				modalScope.calendarClass = 'week-select-calendar';

				modalScope.calendar_subtitle = this.chooserWeekTitleHtml
			}

			///  Calendar Logic:
			var currentDay = this.dayDateStr(calendarData.start_day);

			///  Initialize week stuff
			modalScope.weeks = [];
			var currentWeek = { 
				weekClass: 'non-shippable-week',
				checkboxClass: 'fa fa-circle',
				days:[],
				ship_calendar_group_id: '',
				grouped_week_start: false,
				grouped_week_end: false
			};

			/// Christmas shipping
			var d = new Date();
			var year = d.getFullYear();

			var today = new Date();

			// Define the current time string
			var dateObj = new Date();
			var newHour = parseInt(dateObj.getUTCHours())+1;
			var newTime =	+ (newHour < 10 ? '0' : '') + newHour
				+ ":"
				+ (dateObj.getUTCMinutes() < 10 ? '0' : '') + dateObj.getUTCMinutes()
				+ ":"
				+ (dateObj.getUTCSeconds() < 10 ? '0' : '') + dateObj.getUTCSeconds();

			/// Then loop until we get to the end of the month
			for(var i = 0 ; i <= 41 ; i++) {
				var dayStr = this.formatDate(currentDay, "YYYY-MM-DD");

				var jsDateObj = new Date(dayStr+" 13:00:00");
				var jsCertDate = new Date(dayStr+" "+newTime);
				var month = jsDateObj.getMonth();
				var dow = jsDateObj.getDay();
				var dom = jsDateObj.getDate();
				var dayObj = { dayClass : 'non-shippable-day'};

				var outOfRange = false;

				if(
					this.minDeliveryDate && this.minDeliveryDate.getTime() > currentDay.getTime() 
					|| this.maxDeliveryDate && currentDay.getTime() > this.maxDeliveryDate.getTime()
					|| ((this.isEdeliveryMode || this.isAnnounceItMode) && ((jsCertDate.getTime() <= today.getTime()) && (jsCertDate.toDateString() != today.toDateString())))
				){
					outOfRange = true;
				}

				if((this.isEdeliveryMode || this.isAnnounceItMode) && !outOfRange) {
					dayObj.dayClass = 'shippable-day'
				}
				if ( typeof calendarData.days[dayStr] != 'undefined' && ! outOfRange ) {
					dayObj = calendarData.days[dayStr];
					if(typeof(calendarData.days[dayStr].shippable) != 'undefined' && !calendarData.days[dayStr].shippable) {
						dayObj.dayClass = 'non-shippable-day';
					} else {
						dayObj.dayClass = calendarData.days[dayStr].best_ship_method.erp_ship_method;
					}

					if( dayObj.dayClass != 'non-shippable-day' && (modalScope.calendarClass == 'day-select-calendar' || dow != 6)) {
						dayObj.dayClass += ' shippable-day';
					} else {
						dayObj.dayClass += ' non-shippable-day';
					}
					
					if(typeof(dayObj.best_ship_cost) != 'undefined') {
						dayObj.ship_cost = (parseFloat(dayObj.best_ship_cost) - this.shippingDiscount).toFixed(2);
					}

					if ( typeof(calendarData.days[dayStr].best_ship_method) != 'undefined'
						&&
						(
							calendarData.days[dayStr].best_ship_method.method_code == '3_day_ground'
							|| calendarData.days[dayStr].best_ship_method.method_code == '2nd_day_week_mode'
						)
						&& currentWeek.weekClass == 'non-shippable-week'
					) {
						currentWeek.weekClass = 'shippable-week';
						currentWeek.checkboxClass = 'fa fa-circle-o';

						if ( this.selectedDeliveryDate && this.formatDate(this.selectedDeliveryDate, "YYYY-MM-DD") == dayStr ) {
							currentWeek.weekClass += ' selected-week';
							currentWeek.checkboxClass = 'fa fa-check-circle';
						}
					}

					if ( this.selectedDeliveryDate && this.formatDate(this.selectedDeliveryDate, "YYYY-MM-DD") == dayStr ) {
						this.clickedDay = dayStr;
						this.clickedDayData = calendarData.days[this.clickedDay];

						// showSelectedDate is set when the user chooses calendar type above
						// By default they don't want the selected date highlighted...but once selected...show
						if(showSelectedDate) { dayObj.dayClass += ' selected-day'; }
							
					}

					if(this.isEdeliveryMode) { dayObj.dayClass = 'shippable-day' }
				}

				// 5-Day shipping - re-enable mondays and saturdays
				if(this.recipient.five_day_shipping && modalScope.calendarClass == 'week-select-calendar' && ((dow == 1 && typeof(calendarData.days[dayStr]) == 'undefined') || dow == 6) && (jsDateObj.getTime() > d.getTime())) {
					dayObj.dayClass = dayObj.dayClass.replace('non-shippable-day', 'shippable-day');
				}

				//set flag to show Free Shipping value on calendar
				dayObj.freeShip = (dayObj.dayClass == 'shippable-day') ? this.recipient.freeShip : false;
				dayObj.isECert  = this.isEdeliveryMode;
				dayObj.day = dayStr;
				dayObj.date = currentDay.getDate();

				currentWeek.days.push(dayObj);
				currentWeek.ship_calendar_group_id = '';

				///  Iterate
				if ( currentWeek.days.length >= 7 ) {

					if( typeof weekGroups != 'undefined' ){

						if( typeof weekGroups[ currentWeek.days[0].day ] != 'undefined' ){

							// Data
							var weekData = weekGroups[ currentWeek.days[0].day ];

							// Set the applicable classes
							if( weekData.grouping == 'start' ){
								currentWeek.grouped_week_start = true;
								currentWeek.weekClass += ' group-first-week';
								currentWeek.ship_calendar_group_id = weekData.ship_calendar_group_id;
							}
							if( weekData.grouping == 'end' ){
								currentWeek.grouped_week_end = true;
								currentWeek.weekClass += ' group-second-week';
								currentWeek.ship_calendar_group_id = weekData.ship_calendar_group_id;
							}

						}

					}

					modalScope.weeks.push(JSON.parse(JSON.stringify(currentWeek)));
					currentWeek = { 
						weekClass: 'non-shippable-week',
						checkboxClass: 'fa fa-circle',
						days:[],
						ship_calendar_group_id: '',
						grouped_week_start: false,
						grouped_week_end: false
					};
				}
				currentDay = new Date(currentDay.getTime() + (86400 * 1000));
			}

			// Handle ship day upgrade options
			modalScope.upgradeAvailable = true;
			modalScope.upgradeShippingSelected = this.upgradeShippingSelected;
			var argMonth = new Date( this.recipient.selected_delivery_date ).getMonth();
			var newMonth = new Date( calendarData.start_month_label ).getMonth();
			if( argMonth == newMonth ) {
				if( typeof this.recipient.selected_delivery_date != 'undefined' && this.recipient.selected_delivery_date) {
					var dateObj = new Date(this.recipient.selected_delivery_date);
					var dayStr = this.formatDate(dateObj, "YYYY-MM-DD");
					if( typeof(calendarData.days[dayStr]) != 'undefined' && calendarData.days[dayStr].ship_methods.length == 1 ) {
						modalScope.upgradeAvailable = false;
					} else if( typeof(calendarData.days[dayStr]) != 'undefined' && this.recipient.ship_id && this.recipient.ship_id != calendarData.days[dayStr].best_ship_id && this.recipient.ship_id != 5 ) {
						modalScope.upgradeShippingSelected = true;
					}
				}
			}

			if( modalScope.showIfDeliveryMode && modalScope.upgradeAvailable ) {
				modalScope.shippingUpgradeCheckboxVisibility = 'visibility: visible';
			} else if( modalScope.showIfDeliveryMode && !modalScope.upgradeAvailable ) {
				modalScope.shippingUpgradeCheckboxVisibility = 'visibility: hidden';
			} else {
				modalScope.shippingUpgradeCheckboxVisibility = 'display: none';
			}

			return modalScope;
		},
		loadMonth(newMonth) {
			this.loading = true

			let theMonth = '';
			if(newMonth) {
				this.currentMonth = newMonth;
				theMonth = newMonth;
			} else {
				if ( this.selectedDeliveryDate ) { theMonth = this.formatDate(this.selectedDeliveryDate, "YYYY-MM-DD"); }
				else if(this.minDeliveryDate) { theMonth = this.formatDate(this.minDeliveryDate, "YYYY-MM-DD");  }
				else { theMonth = this.formatDate(new Date().toJSON(), "YYYY-MM-DD") }
			}

			let calendarRequestData = {}
			if(this.isAnnounceItMode) {
				calendarRequestData = {
					calendar_type: this.calendarType,
					clin_id: typeof(this.recipient.items) != 'undefined' ? this.recipient.items[0].clin_id : null,
					month: theMonth,
					ship_date: this.recipient.announce_it_time,
					ship_id: 24,
					ship_postal_code: this.recipient.postal_code,
					ship_state: this.recipient.state,
				}
			} else {
				calendarRequestData = {
					calendar_type: this.calendarType,
					clin_id: typeof(this.recipient.items) != 'undefined' ? this.recipient.items[0].clin_id : null,
					month: theMonth,
					ship_date: this.recipient.shipping.delivery_date,
					ship_id: this.recipient.shipping.ship_id,
					ship_postal_code: this.recipient.postal_code,
					ship_state: this.recipient.state,
					shipping_discount_code: this.fullCart.discount_code
				}
			}

			axios.post('/api/shipping/calendar', calendarRequestData)
				.then(response => {
					///  Server always returns 2 months worth
					this.__monthCache[response.data.month_date]      = response.data.month;
					this.__monthCache[response.data.next_month_date] = response.data.next_month;

					if ( response.data.month.has_deliverable_days == true ) {
						this.currentMonth = this.monDateStr(response.data.month_date);
						this.modalScope = this.prepareCalendarModalScope(response.data.month, false /* showSelectedDate */, response.data.week_groups);
					} else {
						this.currentMonth = this.monDateStr(response.data.next_month_date);
						this.modalScope = this.prepareCalendarModalScope(response.data.next_month, false, response.data.week_groups);
					}

					if(this.calendarType == 'week' && !this.showCalendarWeekMode) {
						this.showCalendarWeekMode = true;
					} else if(this.calendarType == 'day' && !this.showCalendarDayMode) {
						this.showCalendarDayMode = true;
					}
				})
				.catch(error => {
					this.$sentry.captureException(error)
					window.bug("Shipping Calendar XHR Error",error) || alert("Sorry a problem occurred with the calendar.  Please wait a few minutes and try again.");
				})
				.finally(() => {
					this.loading = false
				})
		},
		toggleApplyAllFlag() {
			this.applyAllSelected = !this.applyAllSelected
		},
		goNextMonth() {
			if (this.loading) return

			var nextMonth = this.$dayjs(this.currentMonth).add(1, 'month').toDate()
			if ( this.maxDeliveryDate && this.maxDeliveryDate.getTime() < nextMonth.getTime() ) { return; }

			this.loadMonth(nextMonth, this.recipient.shipping.ship_select_type);
		},
		goPrevMonth() {
			if (this.loading) return

			var prevMonth = this.$dayjs(this.currentMonth).subtract(1, 'month').toDate()
			prevMonth.setDate(15); // set it to the 15th of that month
			if ( this.formatDate(prevMonth, "YYYY-MM") < this.formatDate(new Date(), "YYYY-MM") ) { return; }
			if ( 	   this.minDeliveryDate 
				&& this.minDeliveryDate != 'Invalid Date' 
				&& this.formatDate(this.minDeliveryDate, "YYYY-MM") > this.formatDate(prevMonth, "YYYY-MM")
			) { return; }

			this.loadMonth(prevMonth, this.recipient.shipping.ship_select_type);
		},
		weekMouseOver(index) {
			if(index > 0 && this.modalScope.weeks[index].grouped_week_end && !this.modalScope.weeks[index-1].weekClass.includes('active')) {
				this.modalScope.weeks[index-1].weekClass = this.modalScope.weeks[index-1].weekClass.concat(' active');
			}
		},
		weekMouseLeave(index) {
			if(index > 0 && this.modalScope.weeks[index].grouped_week_end) {
				this.modalScope.weeks[index-1].weekClass = this.modalScope.weeks[index-1].weekClass.replace(' active', '');
			}
		},
		shippableWeekClick(index) {
			if(this.modalScope.weeks[index].weekClass.includes('non-shippable')) { return }
			var currentMonthData = this.modalScope.calendarData;

			// Add to modal scope
			if( this.modalScope.weeks[index].ship_calendar_group_id ){
				this.modalScope.ship_calendar_group_id = this.modalScope.weeks[index].ship_calendar_group_id;
			}

			// remove "selected-week" class from all weeks
			// Also remove set all checkboxClass back to un-checked
			for(var i = 0; i < this.modalScope.weeks.length; i++) {
				this.modalScope.weeks[i].weekClass = this.modalScope.weeks[i].weekClass.replace(' selected-week', '')
				if(this.modalScope.weeks[i].checkboxClass != 'fa fa-circle') {
					this.modalScope.weeks[i].checkboxClass = 'fa fa-circle-o'
				}
			}

			// If week clicked was first in a group - move to second
			// also set selected week class
			this.modalScope.weeks[index].weekClass = this.modalScope.weeks[index].weekClass.concat(' selected-week')
			this.modalScope.weeks[index].checkboxClass = 'fa fa-check-circle'
			if(this.modalScope.weeks[index].grouped_week_start) {
				index++
				this.modalScope.weeks[index].weekClass = this.modalScope.weeks[index].weekClass.concat(' selected-week')

				this.modalScope.weeks[index].checkboxClass = 'fa fa-check-circle'
			} else if(this.modalScope.weeks[index].grouped_week_end) {
				this.modalScope.weeks[index-1].weekClass = this.modalScope.weeks[index-1].weekClass.concat(' selected-week')
				this.modalScope.weeks[index-1].checkboxClass.replace('fa-circle-o', '')
				this.modalScope.weeks[index-1].checkboxClass = 'fa fa-check-circle'
			}

			for(var i = 0; i < this.modalScope.weeks[index].days.length - 1; i++) {
				var dateObj = new Date(this.modalScope.weeks[index].days[i].day + "T12:00:00Z")
				// compensate for shipping day exceptions
				let targetDOW = 5
				if(this.modalScope.weeks[index].days[i].dayClass === 'non-shippable-day') {
					continue
				} else {
					targetDOW = dateObj.getDay()
				}

				if(dateObj.getDay() == targetDOW) {
					this.selectedDeliveryDate = dateObj;
					this.clickedDayData = this.modalScope.weeks[index].days[i];
					this.clickedDay = this.modalScope.weeks[index].days[i].day;
				}
			}
				
			// Let App Know the ship date has changed
			if(this.mode == 'additional-shipments') {
				emitter.emit('additional-shipment-date-change', {clinId: this.clinId, recurring_order: this.recurringOrder, clicked_day: this.clickedDay})
			} else {
				this.cartStore.recipientChangeShipDate(this.recipient, this.clickedDayData, this.calendarType, this.applyAllSelected, this.recipient.cart_type)
			}

			setTimeout(() => {
				this.showCalendarWeekMode = false
			}, 900);
		},
		shippableDayClick(weekIndex, dayIndex, dayData) {
			if (dayData.dayClass.includes('non-shippable-day')) return

			// REMOVE PREVIOUSLY SELECTED DAY CLASS
			for(var i = 0; i < this.modalScope.weeks.length; i++) {
				for(var j = 0; j < this.modalScope.weeks[i].days.length; j++) {
					this.modalScope.weeks[i].days[j].dayClass = this.modalScope.weeks[i].days[j].dayClass.replace(' selected-day', '')
				}
			}

			// NOW SET THE SLECTED DAY CLASS
			this.modalScope.weeks[weekIndex].days[dayIndex].dayClass = this.modalScope.weeks[weekIndex].days[dayIndex].dayClass.concat(' selected-day');

			// SAVE EVERYTHING
			var dateObj = new Date(this.modalScope.weeks[weekIndex].days[dayIndex].day+"T12:00:00Z")
			this.selectedDeliveryDate = dateObj;
			this.clickedDayData = dayData;
			this.clickedDay = this.modalScope.weeks[weekIndex].days[dayIndex].day;
			if(!this.isAnnounceItMode && !this.isEdeliveryMode) {
				// Let App Know the ship date has changed
				this.cartStore.recipientChangeShipDate(this.recipient, this.clickedDayData, this.calendarType, this.applyAllSelected, this.recipient.cart_type)
			} else {
				this.recipient.announce_it_time = this.modalScope.weeks[weekIndex].days[dayIndex].day
			}

			setTimeout(() => {
				this.showCalendarDayMode = false
				if(this.isAnnounceItMode || this.isEdeliveryMode) {
					this.showTimeSelectModal = true
				}
			}, 900);
		},
		processTimeSelect(mode) {
			if(mode == 'use-selected-time') {
				if (!this.deliveryTimeSelected) return

				const announceItDateTime = this.$dayjs(`${this.clickedDay} ${this.deliveryTimeSelected}`)

				const newTime = announceItDateTime.format('YYYY-MM-DD HH:mm:00')

				if(this.isAnnounceItMode) {
					this.recipient.announce_it_time = newTime
				} else if(this.isEdeliveryMode) {
					this.recipient.shipping.delivery_date = newTime
					this.recipient.shipping.ship_date = newTime
					this.recipient.shipping.pickup_time_central = `${announceItDateTime.format(`h:mm a`)} Central`
				}
			} else if(mode == 'send-immediately') {
				if(this.isAnnounceItMode) {
					this.recipient.announce_it_time = 'send-immediately';
				} else if(this.isEdeliveryMode) {
					this.recipient.shipping.pickup_time_central = 'Send Immediately'
				}
			}

			if(this.isAnnounceItMode) {
				this.cartStore.recipientChangeGiftMessages(this.recipient)
			} else if(this.isEdeliveryMode) {
				this.cartStore.recipientChangeShipDate(this.recipient, this.clickedDayData, this.calendarType, this.applyAllSelected, this.recipient.cart_type)

				// Reset apply all flag after submit
				this.applyAllSelected = false
			}

			setTimeout(() => {
				if(mode == 'use-selected-time') {
					this.showTimeSelectModal = false
				} else {
					this.showCalendarDayMode = false
				}
			}, 500);
		},
		dayDateStr(str) { return new Date(new Date(str+'T00:00:00Z').getTime() + (12*3600*1000)); },
		monDateStr(str) { return this.dayDateStr(str+'-01'); },
	}
}
</script>

<style>
	.ship-cal-modal,
	.time-select-modal {
		position: fixed; /* Stay in place */
		z-index: 100; /* Sit on top */
		left: 0;
		top: 0;
		width: 100%; /* Full width */
		height: 100%; /* Full height */
		overflow: auto; /* Enable scroll if needed */
		background-color: rgb(0,0,0); /* Fallback color */
		background-color: rgba(0,0,0,0.7); /* Black w/ opacity */

		input[type=checkbox] {
			position: absolute;
			z-index: -1000;
			left: -1000px;
			overflow: hidden;
			clip: rect(0 0 0 0);
			height: 1px;
			width: 1px;
			margin: -1px;
			padding: 0;
			border: 0;
		}
	}

	/* Modal Content/Box */
	.ship-cal-modal-content {
		background-color: #fefefe;
		margin: 15% auto; /* 15% from the top and centered */
		padding: 20px;
		border: 1px solid #888;
		position: relative;
	}

	.shipping-calendar-chooser-modal {
		max-width: 370px
	}

	.week-mode-calendar-modal,
	.day-mode-calendar-modal {
		max-width: 600px;
	}

	/* The Close Button  */
	.ship-cal-modal button.ship-cal-close {
		background: transparent;
		border: 0;
		font: normal normal normal 14px/1 FontAwesome;
		font-size: 30px;
		overflow: hidden;
		position: absolute;
		right: 17px;
		top: 12px;
		width: 32px;
		height: 30px;
		color: #605255;
	}
	.ship-cal-modal button.ship-cal-close::before {
		content: '\f057 \00A0\00A0\00A0\00A0\00A0\00A0\00A0\00A0';
	}

	.ship-cal-modal button.ship-cal-close:hover,
	.ship-cal-modal button.ship-cal-close:focus {
		color: black;
		cursor: pointer;
	}
</style>